section {
  width: 0%;
}

.wrapper {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
  display: flex;
  flex-direction: column;
  flex: 1 1 100%;
}

.content-wrapper {
  padding: 24px;
  display: flex;
  flex-direction: column;
  height: 100%;
  flex: 1 1 auto;
}

.content-wrapper-header {
  display: flex;
  justify-content: space-between;
}

.content-wrapper-main {
  margin-top: 10px;
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
}

/**CSS TEST* WRAPPER*/

.list-style-none {
  display: inline;
  list-style: none;
}

.list-style-none strong::after {
  content: " / ";
}

.text-active {
  color: #252546;
}

.text-inactive {
  color: #67677e;
}

.items-center {
  align-items: center;
}

.title-logo {
  margin-left: 10px;
  font-size: 30px;
  cursor: pointer;
}
