footer {
  display: flex;
  height: 10vh;
  justify-content: center;
  align-items: center;
  color: #252546;
  background-color: #f7f7f7;
  font-size: 12px;
}

.center {
  width: 450px;
  text-align: center;
}

.center a {
  font-weight: bold;
  color: #252546;
  text-decoration: none;
}
