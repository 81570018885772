header {
  text-align: right;
  padding: 10px;
  background-color: white;
  flex: 0 1 auto;
}

.title-logo {
  margin-left: 10px;
  font-size: 30px;
  cursor: pointer;
}

.title-logo p {
  color: #fff;
  font-family: Arial, Helvetica, sans-serif;
}

/* .content-options {
  float: left;
  width: calc(100% - 240px);
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  background-color: #fff;
  margin-left: 240px;
} */

.options {
  display: flex;
  margin-right: 10px;
}

.buttonLogout {
  background-color: transparent;
  border: none;
  cursor: pointer;
}

.options a {
  font-size: 13.3px;
  margin-right: 10px;
}
