.content {
  margin-left: 220px;
  flex: 1 1 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  background-color: #eff3f6;
  width: calc(100% - 220px);
}

.body {
  flex-direction: column;
  display: flex;
  height: 100%;
}

.menu {
  position: fixed;
  height: 100%;
  width: 220px;
  background-color: #252546;
}
