.textarea {
  padding: 10px;
  margin-top: 5px;
}

.textarea:focus-visible {
  outline: none;
}

.textarea:hover {
  border: 1px solid #000;
}

.textarea:focus {
  border: 2px solid #1976d2;
}
