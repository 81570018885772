.ul-tabs {
  list-style: none;
  padding: 10px 0px 5px 24px;
  border-bottom: 1px solid #d0cecf;
}

.li-div {
  margin: 0 4px 4px 0;
  float: left;
  padding: 6px 14px 6px 14px;
  border: 1px solid #d0cecf;
  cursor: pointer;
  user-select: none;
  font-size: 14px;
}

.li-div:hover {
  background-color: #dddddd;
}
