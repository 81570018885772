aside {
  height: 100%;
  width: 220px;
  position: fixed;
}

.logo {
  cursor: pointer;
  padding: 20px;
}

.content-logo {
  display: flex;
  justify-content: center;
  flex-direction: row;
  height: 20%;
}

.sidebar {
  height: 80%;
  overflow-y: auto;
  scrollbar-color: rgba(0, 0, 0, 0.5) rgba(0, 0, 0, 0);
  scrollbar-width: thin;
}

.sidebar::-webkit-scrollbar {
  -webkit-appearance: none;
}

.sidebar::-webkit-scrollbar:vertical {
  width: 10px;
}

.sidebar::-webkit-scrollbar-button:increment,
.sidebar::-webkit-scrollbar-button {
  display: none;
}

.sidebar::-webkit-scrollbar:horizontal {
  height: 10px;
}

.sidebar::-webkit-scrollbar-thumb {
  background-color: #67677e;
  border-radius: 20px;
  border: 2px solid #252546;
}

.sidebar::-webkit-scrollbar-track {
  border-radius: 10px;
}

.sidebar ul {
  list-style-type: none;
}

.sidebar ul li {
  padding: 10px 20px;
  width: 100%;
  height: 50px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #67677e;
  font-size: 12px;
  text-decoration: none;
  border-bottom: 1px solid #67677e;
  font-weight: bold;
}

.sidebar ul li:hover {
  color: #fff;
}
