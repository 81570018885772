.login {
  display: flex;
  height: 100vh;
  width: 100%;
}

.content-login-logo {
  float: left;
  width: 50%;
  background-color: #252546;
}

.content-login-form {
  float: left;
  width: 50%;
  background: #fff;
}

.content-items-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.content-login-form-main {
  width: 400px;
  height: auto;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgb(0 0 0 / 10%), 0 8px 16px rgb(0 0 0 / 10%);
  background-color: #fff;
}

.content-login-form-header {
  height: 80px;
  width: 100%;
}

.content-login-form-body {
  padding: 20px;
}

.content-login-form-input {
  width: 100%;
}

.content-login-form-footer {
  height: 20px;
}
